.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.field-label {
  margin: 0px;
  padding: 0px;
  border: 1px solid black;
}

.field-value {
  margin: 0px 0px;
  padding: 5px;
  height: 40px;
  background-color: transparent;
  border-bottom: 1px solid darkslategray;
}

.account-form-paper {
  margin: 0px;
  padding: 0px;
}

.account-form-area {
  margin: 10px;
  border: 1px solid red;
}

.account-form-title {
  width: 98%;
  text-align: center;
  background-color: #282c34;
  color: wheat;
  font-size: 4vh;
  margin: auto 10px;
}

.avatar {
  border-radius: 50%;
  border: 1px solid black;
  margin: 5px;
}

.form-custom-label {
  font-size: 1rem;
  text-align: left;
  vertical-align: middle;
}
.form-field {
  font-size: 0.9rem;
}
.alight-text-left {
  text-align: left;
}

.form-field-error-message {
  font-size: 0.8rem;
  font-style: oblique;
  color: red;
  text-align: left;
  margin: 0px;
  display: flex;
}

.form-user-button {
  margin: 5px;
}
