.part-type-filter-container {
  margin: 5px 10px;
  text-align: left;
}
.part-type-filter-container span {
  margin: 0px 5px;
}

.part-withdrawer-form-line {
  border-top: 2px solid #555;
  height: 5px;
  display: block;
}
.has-border {
  border: 1px solid black;
  margin: 5px;
}

.part-withdrawer-form-panel-field {
  width: 350px;
  margin: 5px;
}
.part-withdrawer-form-image-field {
  width: 150px;
  margin: 5px;
}

.part-withdrawer-form-onhand-field {
  width: 350px;
  margin: 0px;
}
.part-withdrawer-form-withdraw-buttons {
  width: 250px;
  margin: 0px;
}

.master-button-panels {
  margin-top: 10px;
}
.light-line {
  border-top: 1px solid #888;
  height: 2px;
  display: block;
}
